<template>
  <div class="bbr-exercises--new-instructions">
    <v-row>
      <v-col lg="8" md="12">
        <div class="mb-4 d-flex justify-space-between align-center">
          <h2 class="mr-auto">Exercise Steps</h2>

          <v-btn
            class="mr-5 px-7"
            color="primary"
            :loading="form.$busy"
            @click="saveForLater"
            text
          >
            <span v-if="!form.completed">Save & Finish Later</span>
            <span v-else>Save & Close</span>
          </v-btn>

          <v-btn
            v-if="hasChanges && !hasEmptyInstruction"
            class="ml-3 bg-primary-gradient primary"
            :loading="form.$busy"
            @click="saveChanges"
          >
            <v-icon class="mr-3">
              {{ icons.save }}
            </v-icon>
            Save Changes
          </v-btn>

          <v-btn
            v-if="!hasChanges"
            class="px-12 bg-primary-gradient primary next-button"
            :loading="form.$busy"
            @click="saveAndNext"
            depressed
            text
          >
            Next
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col lg="8" md="12">
        <exercise-instruction
          :items="form.instructions"
          :is-saving="form.$busy"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ExerciseInstruction from '@/components/forms/exercises/ExerciseInstructions'
import ExerciseMixin from '../mixins/ExerciseMixin'

import { mdiCheck, mdiArrowRight } from '@mdi/js'

export default {
  name: 'ExerciseInstructions',

  mixins: [ExerciseMixin],

  components: {
    ExerciseInstruction,
  },

  data() {
    return {
      icons: {
        save: mdiCheck,
        next: mdiArrowRight,
      },
    }
  },

  computed: {
    hasInstrucitons() {
      return !!this.form.instructions.length
    },

    hasEmptyInstruction() {
      if (!this.hasInstrucitons) return false

      return !!this.form.instructions.filter((instruction) => {
        return !instruction
      }).length
    },
  },

  methods: {
    async saveForLater() {
      if ((await this.save()).error) return

      this.redirectToListView()
    },

    async saveAndNext() {
      if ((await this.save()).error) return

      this.redirectIf(this.isCompleted, 'exercise.swaps') ||
        this.redirectIf(!this.isCompleted, 'new.exercise.swaps')
    },

    async saveChanges() {
      await this.save()
    },
  },
}
</script>

<style lang="scss" scoped>
.moving-instruction {
  opacity: 0.1 !important;
  color: transparent !important;
  border: 3px dashed var(--v-primary-base) !important;
  background-color: var(--v-grey-base) !important;

  .instruction-input {
    opacity: 0 !important;
    color: transparent !important;
    background-color: var(--v-grey-base) !important;
  }
}

.sortable-drag {
  opacity: 1 !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  background-color: white !important;
  border: 1px solid var(--v-grey-base) !important;

  .v-divider {
    visibility: hidden !important;
  }
}
</style>
